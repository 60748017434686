import React from 'react';
import Logo from '../../images/Group-94.png';
import CallUs from '../../images/call-us-logo-removebg-preview.png';
// import Google from '../../images/MaskGroup-2.png';
import instgram from '../../images/insta-white.png';
import facebook from '../../images/fb-white.png';
import tiktok from '../../images/tiktok-white.png';
import youtube from '../../images/Youtube-white.png';
import linkedin from '../../images/in-white (2).png';
import snap from '../../images/snap-white.png';
import { useTranslation } from 'react-i18next';

import './footer.css'
import { Link } from 'react-router-dom';
function Footer() {
  const phoneNumber = "1234567890"; 
  const { t, i18n } = useTranslation();
  return (
    <div className='mt-5 '>
      <div class="bg-image  mb-0">
        <div className='container'>
  <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5  p-top  ">
    <div class="col-md-3 ">
      <div className='mb-3 t-column'>
      <Link to="/" class="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
      <img className='logo'  src={Logo}/>
        {/* <svg class="bi me-2" width="40" height="32"><use xlink:to="#bootstrap"></use></svg> */}
      </Link>
      <p class=" t-footer">{t('In todays globalized business environment, language learning has become a crucial element of professional development. As companies increasingly operate across borders')}</p>
        <div className='row social-footer'>
<Link className='col-2 ' to="https://www.facebook.com/learnovative.uk/"><img className='fb'   src={facebook} /></Link>
<Link className='col-2 ' to='https://www.instagram.com/learnovative.uk/'><img className=' insta'   src={instgram} /></Link>
<Link className='col-2' to='https://www.tiktok.com/@learnovative.uk?lang=en'><img className='tiktok'   src={tiktok} /></Link>
<Link className='col-2' to='https://www.youtube.com/@Learnovative-ok4dx'><img  className='youtube'  src={youtube} /> </Link>
<Link className='col-2 ' to='https://www.linkedin.com/company/learnovative-uk/?viewAsMember=true'><img className='linkedin'   src={linkedin } /> </Link>
<Link className='col-2 ' to='https://snapchat.com/t/JkI6Oqr8'><img className='snap'   src={snap } /> </Link>



</div>
</div>

    
    </div>
    <div className='col-md-1'></div>
    <div class="col-md-2">
      <div className='mt-5 t-column'>
      {/* <h5 className='text-light'>About</h5> */}
      <ul class="nav flex-column text-startt leftt">
       <li class="nav-item mb-2"><Link to="/About" class="nav-link p-0 text-light">{t('About')}</Link></li> 
       <li class="nav-item mb-2"><Link to="/CourseCatalog" class="nav-link p-0 text-light">{t('Explore')} </Link></li>
       <li class="nav-item mb-2"><Link to="/Contacts" class="nav-link p-0 text-light">{t('Contact Us')}</Link></li>
        <li class="nav-item mb-2"><Link to="/Instructors" class="nav-link p-0 text-light">{t('Team')}</Link></li>
      
      </ul>
      </div>
    </div>
    <div class="col-md-3 ">
  <div className='mt-5 t-column' >
      <ul class="nav flex-column text-startt lefttt">
        <li class="nav-item mb-2"><Link to="/CourseCatalog" class="nav-link p-0 text-light">{t('Courses')}</Link></li>
        <li class="nav-item mb-2"><Link to="/Instructors" class="nav-link p-0 text-light">{t('Instructors')} </Link></li>
        {/* <li class="nav-item mb-2"><Link to="#" class="nav-link p-0 text-light">Subscription Plans </Link></li> */}
        <li class="nav-item mb-2"><Link to="/TermsAndCondition" class="nav-link p-0 text-light">{t('Terms & Condition')}</Link></li>
     
      </ul>
      </div>
    </div>
    <div class="col-md-3  ">
      {/* <div className='soon-footer '> */}
     
      <div className='mt-2 resp m-top' >
      {/* <h4 className='soon-footer'>soon</h4> */}
      <Link to={`tel:${phoneNumber}`} class="d-flex align-items-centerr mb-3 link-body-emphasis text-decoration-none ">
      <img  className='app' src={CallUs}/>

      </Link>
      {/* <h5 className='soon-footer'>0201040277714</h5> */}
      {/* <Link to="/" class="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none">
      <img className='google'  src={Google}/>
      </Link> */}
      </div>
      </div>
  </footer>
  </div>
</div>
    </div>
  )
}
export default Footer;