import React, { useEffect, useState, useContext } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../header/Header';
import Footer from "../footer/Footer";
import './SingleInstructors.css';
import CardComponentAll from '../cardComponentAll/cardComponentAll';
import LanguageContext from '../../context/LanguageContext';
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
function SingleInstructor() {
  const whatsappNumber = "0201040277714"
  const messages = "Hello, I would like to know more about your services!";

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(messages)}`;
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const { instructorID } = useParams();
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  useEffect(() => {
    const langId = language === "en" ? 1 : 2;
    const apiUrl = `https://na.learnovative.uk/api/v1/Front/Instructor/By/${instructorID}/${langId}`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((data) => {
      if (data && data.data) {
        setData(data.data);
        if (data.data.length === 0) {
          // No data returned, navigate to AllCourses component
          navigate('/Instructors');
      }
        setError(false);
      } else {
        setError(true);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      setError(true);
    });
  }, [language, instructorID,navigate]);

  return (
    <div>
      <Link
                      to={whatsappLink}
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{
                         position: 'fixed',
                         cursor:'pointer',
                         bottom: '100px',
                         right: '20px',
                         textDecoration: 'none',
                         zIndex:'100000000000',
                       }}
                     >
                       <FaWhatsapp size={50} color="#25D366" />
                     </Link>
      {/* <Header /> */}
      <div className='bg-color-instructor'></div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-1'>
            <span className='line-headline-instructor '>{t('Instructors')}</span>
          </div>
          <div className='col-md-1'>
            <span className='line-head-instructor '>{t('Single Instructor')}</span>
          </div>
        </div>
      </div>

      {error ? (
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 mt-4'>
              <h1 className='headline-instructor-sec2'>{t('No data found')}</h1>
              <p className='topic'>{t('Sorry, we could not find any information about this instructor. Please try again later or contact support for assistance.')}</p>
            </div>
          </div>
        </div>
      ) : (
        data.map((item) => (
          <div key={item.id}>
            <div className='bg-color-instructor-top'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-3'>
                    <img className='instructor-imgg m-auto mt-3' src={item.profileImgIRL} alt='Instructor' />
                  </div>
                  <div className='col-md-3 mb-5 media-instructor'>
                    <h1 className='headline-instructor col '>{item.insName}</h1>
                    <h6 className='title-job-instructor'>{item.insTitle}</h6>
                  </div>
                  <div className='col-md-3'></div>
                </div>
              </div>
            </div>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8 mt-4'>
                  <h1 className='headline-instructor-sec2'>{t('About')}</h1>
                  <p className='topic'>{item.insBio}</p>
                </div>
              </div>
            </div>
            <div className='container'>
              <h1 className='headline-instructor mt-5'>{t('Courses you might like')}</h1>
              <div className='row'>
                <CardComponentAll />
              </div>
            </div>
          </div>
        ))
      )}

      {/* <Footer /> */}
    </div>
  );
}

export default SingleInstructor;
