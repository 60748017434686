/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react';
// import './header.css';
import Logo from '../../images/Mask Group 1.png';
import ar from '../../images/ar.png';
import en from '../../images/en.png';


import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageContext from '../../context/LanguageContext';
import { Link } from "react-router-dom";

function Header() {
  const { t } = useTranslation();
  const { language, changeLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleRegisterClick = () => {
    navigate('/Register');
  };

  const handleLoginClick = () => {
    window.open('http://student.learnovative.uk/', '_blank'); // Opens in a new tab
  };

  const handleLanguageSelection = (courseLanguage) => {
    sessionStorage.setItem('courseLanguage', courseLanguage);
  };

  return (
    <div>
      <header className="p-3 marginn">
        <div className="container">
          <div className="row justifyy">
            <div className='col-6 responsive-header'>
              <Link to="/" className="d-flex align-items-center mb-2 mb-lg-0 text-decoration-none logo-header">
                <img className="logo-img arimg" src={Logo} alt="Logo" />
              </Link>
              <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                <span className="navbar-toggler-icon">&#9776;</span>
              </button>
            </div>
            <div className='col-6 headerrr'>
              <div className=''>
                <div className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
                  <ul className="nav-ar nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-start mb-md-0">
                  <li><Link to="/About" className="nav-link px-2 text-color">{t('About')}</Link></li>

                    <li className="nav-item dropdown">
                      <Link className="nav-link text-color dropdown-toggle">{t('Explore')}</Link>
                      <ul className="dropdown-menu">
                        <li><Link to="/CourseCatalog"  className="dropdown-item">All</Link></li>
                        <li><Link to="/CourseCatalogDynamic" className="dropdown-item" onClick={() => handleLanguageSelection(1)}>English</Link></li>
                        <li><Link to="/CourseCatalogDynamic" className="dropdown-item" onClick={() => handleLanguageSelection(2)}>German</Link></li>
                      </ul>
                    </li>
                    <li><Link to="/Instructors" className="nav-link text-color">
  {t('Instructors')}
</Link></li>
                    <li><Link to="/Contacts" className="nav-link px-2 text-color">{t('Contact Us')}</Link></li>
                  </ul>
                  <div>
  {language === "en" && (
    <button
      className="btn-lang btn me-2 ml2"
      onClick={() => changeLanguage("ar")}
      style={{
        fontFamily: 'Segoe UI',
        cursor: "pointer",
        backgroundImage: `url(${ar})`, // Arabic flag as background
        backgroundRepeat: "no-repeat",
        backgroundPosition: " left",
        marginTop: '6px',
        backgroundSize: "20px 20px", // Adjust flag size
        paddingLeft: "30px", // Space for the flag
      }}
    >
      Ar
    </button>
  )}
  {language === "ar" && (
    <button
      className="btn-lang btn me-2 ml2"
      onClick={() => changeLanguage("en")}
      style={{
        cursor: "pointer",
        backgroundImage: `url(${en})`, // English flag as background
        backgroundRepeat: "no-repeat",
        backgroundPosition: " right",
        marginTop: '11px',
        backgroundSize: "20px 20px",
        paddingRight: "30px",
      }}
    >
      En
    </button>
  )}
</div>

                  <div className="d-flex form">
                    <button onClick={handleRegisterClick} type="button" className="btn-Register btn me-2">{t('Register')}</button>
                    <button onClick={handleLoginClick} type="button" className="btn-Login btn me-2">{t('Login')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
