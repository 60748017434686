/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState, useRef ,useContext } from 'react';
import Slider from 'react-slick';
import './home.css'
import Italy from '../../images/icon_flag-it.png';
import Arabic from '../../images/icon_flag-ar.png';
import German from '../../images/icon_flag-de.png';
import Frensh from '../../images/icon_flag-fr.png';
import spanish from '../../images/icon_flag-es.png';
import English from '../../images/icon_flag-en.png';
import japan from '../../images/icon_flag-ja.png';

import { useTranslation } from 'react-i18next';
// import CardComponent from '../card/Cardcomponent';
// import SamaCard from '../samaCard/samaCard';
import mySecImage1 from '../../images/Group1326.png';
import mySecImage2 from '../../images/Group 1327.png';
import mySecImage3 from '../../images/Group 1328.png';
import mySecImage4 from '../../images/Group 1329.png';
import mySecImagee from '../../images/Group1331.png';
import user from '../../images/free-user-icon-3296-thumb.png';
import star from '../../images/2.png';
import inst1 from '../../images/inst1.png';
import inst2 from '../../images/inst2.png';
import inst3 from '../../images/inst3.png';
import Header from '../header/Header';
import Footer from "../footer/Footer";
import GetData from '../../services/getData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CardComponentAll from '../cardComponentAll/cardComponentAll';
import { FaWhatsapp } from 'react-icons/fa';
// import myImage from '../../images/Group1331.png';
// import star from '../../images/2.png';
import cap from '../../images/3.png';
import lesson from '../../images/4.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle} from '@fortawesome/free-solid-svg-icons'; // import any specific icons you need

import dolar from '../../images/$.png';
import axios from 'axios';
// import user from '../../images/free-user-icon-3296-thumb.png';
import LanguageContext from '../../context/LanguageContext';
function Home() {
  const whatsappNumber = "0201040277714"
  const message = "Hello, I would like to know more about your services!";

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
  const { language } = useContext(LanguageContext);
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    const langId = language === "en" ? 1 : 2;
    const apiUrl = `https://na.learnovative.uk/api/v1/Front/Instructor/Top/${langId}`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((data) => {
      setInstructors(data.data);
    })
    .catch((error) => console.error('Error:', error));
  }, [language]);

  const { t, i18n } = useTranslation();
  const handleNavigation = (id) => {
    navigate(`/SingleInstructor/${id}`);
  };
  // const navigate = useNavigate();
  const { instructorID } = useParams();
  // const handleClick = () => {
  //   navigate(`/SingleInstructor/${instructorID}`); // Navigate to DetailPage with ID 1
  // };
    const [dataInsta, setdataInsta] = useState([]);

 
    // State for the numbers
    const [studentCount, setStudentCount] = useState(0);
    const [courseCount, setCourseCount] = useState(0);
    const [instructorCount, setInstructorCount] = useState(0);
    const [classCount, setClassCount] = useState(0);
    
    const numbersRef = useRef(null);
  
    useEffect(() => {
      getTopThreeCourse();
  
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Start counting when the element is in view
            startCounting();
            observer.disconnect(); // Disconnect the observer after it has fired
          }
        });
      }, { threshold: 0.5 });
  
      observer.observe(numbersRef.current);
  
      return () => observer.disconnect();
    }, []);
  
    const startCounting = () => {
      animateValue(setStudentCount, 0, 100000, 2000);
      animateValue(setCourseCount, 0, 100, 2000);
      animateValue(setInstructorCount, 0, 10, 2000);
      animateValue(setClassCount, 0, 15, 2000);
    };
    const handleLanguageSelection = (courseLanguage) => {
      sessionStorage.setItem('courseLanguage', courseLanguage);
      navigate('/CourseCatalogDynamic');
    };
    const animateValue = (setFunction, start, end, duration) => {
      let startTimestamp = null;
      const step = timestamp => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        setFunction(Math.floor(progress * (end - start) + start));
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    };
  useEffect(() => {
    getTopThreeCourse();
  }, []);
let [data, setData] = useState([]);
// let [data2, setData2] = useState([]);
async function getTopThreeCourse() {
    
    const _getData = new GetData();
    await _getData.Getdata('Course/Top');
    setData(_getData._ResponseModal.data);
    data = _getData._ResponseModal.data
    console.log('DATA',data)
   
  }

  const navigate = useNavigate();
  const navtoABout = () => {
    navigate('/About'); // Change '/register' to your desired path
  };
  const navtoAllCourses = () => {
    navigate('/CourseCatalog'); // Change '/register' to your desired path
  };
  const navtoAllInstructors = () => {
    navigate('/Instructors'); // Change '/register' to your desired path
  };
  const navtoRegister = () => {
    navigate('/Register'); // Change '/register' to your desired path
  };
  const navtoLogin = () => {
    navigate('/Login'); // Change '/register' to your desired path
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div>
        <Link
     to={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: 'fixed',
        cursor:'pointer',
        bottom: '100px',
        right: '20px',
        textDecoration: 'none',
        zIndex:'100000000000',
      }}
    >
      <FaWhatsapp size={50} color="#25D366" />
    </Link>
      {/* <Header></Header> */}
{/* start navbar */}
{/* <nav className="navbar navbar-expand-lg bg-body-tertiary">
  <div className="container-fluid">
    <Link className="navbar-brand" to="#">
      <img src="your-image-url.jpg" alt="Logo" width="30" height="30" className="d-inline-block align-top" />
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="#">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="#">Link</Link>
        </li>
        <li className="nav-item dropdown">
          <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </Link>
          <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="#">Action</Link></li>
            <li><Link className="dropdown-item" to="#">Another action</Link></li>
            <li><hr className="dropdown-divider"/></li>
            <li><Link className="dropdown-item" to="#">Something else here</Link></li>
          </ul>
        </li>
      </ul>
      <form className="d-flex" role="search">
        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button className="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav> */}

{/* end nabbar */}
{/* start landing  */}
{/* <div className='landing'>
<p className='mainHead'>LEARNOVATIVE</p>
<p className='secHead'>Learn. Improve. Grow. </p>
<p className='thirdHead'> Build your skills through job-ready programs and earn your certification to propel your career.</p>
<div className='ButtonHolder-A'>
<button onClick={navtoABout} className='btnOne'>LEARN MORE</button>
<button className='btnTwo'>SUBSCRIBE</button>
</div>

</div> */}
 {/* start slider */}
 
 <Slider {...settings}>
        <div className='landing'>
          <p className='mainHead'>{t('LEARNOVATIVE')}</p>
          <p className='secHead'>{t('HomeP1')} </p>
          <p className='thirdHead'> {t('HomeP2')}</p>
          <div className='ButtonHolder-A'>
            <button onClick={navtoABout} className='btnOne'>{t('LEARNMORE')}</button>
            <button onClick={navtoLogin} className='btnTwo'>{t('SUBSCRIBE')}</button>
          </div>
        </div>

        <div className='landing2'>
          <p className='mainHead'>{t('LEARNOVATIVE2')}</p>
          <p className='secHead'>{t('Home2P1')}</p>
          <p className='thirdHead'>{t('Home2P2')}</p>
          <div className='ButtonHolder-A'>
            <button onClick={navtoAllCourses} className='btnOne'>{t('EXPLORECOURSES')}</button>
            <button onClick={navtoRegister} className='btnTwo'>{t('SIGNUP')}</button>
          </div>
        </div>

        {/* Add more slides as needed */}
      </Slider>
      {/* end slider */}
{/* end landing */}
{/* start languages */}
<div className='labelBackground'>

<div> 
  <div className="container mt-5">
    <div className="row justify-content-center">
      <div className="col-md-8"> 
        <p className="text-center Label bullet-list-A-A">{t('Whatwanttolearn')}</p>
      </div>
    </div>
  </div>
  <div className="container mt-5">
    <div className="row "> 


      <div className="col-1 col-xl-4 ">
        {/* <img src={German} alt="Logo" className="d-inline-block align-top flag img-fluid" />
        <p className="nation">{t("German")}</p> */}
      </div>
      <div onClick={() => handleLanguageSelection(2)} className="col-5 col-xl-2 ">
        <img src={German} alt="Logo" className="d-inline-block align-top flag img-fluid" />
        <p className="nation">{t("German")}</p>
      </div>
      <div onClick={() => handleLanguageSelection(1)} className="col-5 col-xl-2 ">
        <img src={English} alt="Logo" className="d-inline-block align-top flag img-fluid" />
        <p className="nation">{t('English')}</p>
      </div>
      <div className="col-1 col-xl-4 ">
        {/* <img src={English} alt="Logo" className="d-inline-block align-top flag img-fluid" />
        <p className="nation">{t('English')}</p> */}
      </div>

     

    </div>
  </div>
</div>

{/* end languages */}
{/* start explore */}
<div className="container mt-5">
  <div className="row justify-content-center">
    <div className="col-md-8">
      <p className="text-center Label bullet-list-A2">{t('Exploreinspiring')}</p>
    </div>
  </div>
  {/* <div className="row justify-content-center mt-5">
    <div className="col-6 col-md-4 col-lg-3 col-xl-2 text-center mb-3">
      <button id='clickedBtn' className="btn-A btn-block">English</button>
    </div>
    <div className="col-6 col-md-4 col-lg-3 col-xl-2 text-center mb-3">
      <button className="btn-A btn-block">Spanish</button>
    </div>
    <div className="col-6 col-md-4 col-lg-3 col-xl-2 text-center mb-3">
      <button className="btn-A btn-block">German</button>
    </div>
    <div className="col-6 col-md-4 col-lg-3 col-xl-2 text-center mb-3">
      <button className="btn-A btn-block">Arabic</button>
    </div>
    <div className="col-6 col-md-4 col-lg-3 col-xl-2 text-center mb-3">
      <button className="btn-A btn-block">Business</button>
    </div>
  </div> */}
  <div className="row justify-content-center mt-5">

    {/* {data.map((item, index) => (
          <div className="col-4 col-md-6 col-lg-6 col-xl-4 mb-4 myCard">
          <div className="card-container">
      <div className="card" style={{ width: '19rem' }} key={index}>
        <img src={item.coverImageURL} className="card-img-top" alt="..." />
        <div className="card-body">
          <div className="row">
            <div className="col-4 paraholder">
              <p className='paragraph'> <FontAwesomeIcon icon={faCircle} color='#525252' />{item.langName}</p>
            </div>
            <div className="col-4"></div>
            <div className="col-4 paraholder2">
              <div className='holder'>
                <img className='star-A' src={star} alt="Star" />
                <p className='rate'>4.8</p>
              </div>
            </div>
          </div>

          <div className='rate'></div>
          <p className="para">{item.shortDesc}</p>
          <hr></hr>
          <div className='row'>
            <div className='col'>
              <div className='d-flex justify-content-between'>
                <div className='holder1'>
                  <img src={lesson} alt="Lesson" />
                  <p style={{ color: '#9b9b9b' }}>10 lessons</p>
                </div>
                <div className='holder2'>
                  <img src={cap} alt="Students" />
                  <p style={{ color: '#9b9b9b' }}>100 students</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col'>
              <div className='d-flex justify-content-between'>
                <div className='holder3'>
                  <img src={dolar} alt="Price" />
                  <p className='dolarat'>${item.currencyLabel}</p>
                </div>
                <div className='holder4-A'>
                  <img src={user} alt="Instructor" style={{ width: '20%' }} />
                  <p className='thename-A'>{item.insName}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>   
        </div>
    ))} */}

    <div className="">
    <CardComponentAll></CardComponentAll>
    </div>
    {/* <div className="col-12 col-md-6 col-lg-6 col-xl-4  mb-4 myCard">
      <SamaCard />
    </div>
    <div className="col-12 col-md-6 col-lg-6 col-xl-4  mb-4 myCard">
      <SamaCard />
    </div> */}
  </div>
</div>


<div className='container'>
<div className=' justify-content-center mt-5'>
  <button onClick={navtoAllCourses} className='btnn'>
  {t('LEARNMORE')}
  </button>

</div>
</div>
</div>


{/* end explore */}
{/* start number */}
<div className='nums mt-5' ref={numbersRef}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-sm-6 col-md-3 text-center numHolder'>
              <div className='numholder'>
                <img className='numImg' src={mySecImage1} alt="Number" />
                <p className='ParaOne mt-2'>{studentCount.toLocaleString()}</p>
                <p className='mt-2 paraTwo'>{t('Student')}</p>
              </div>
            </div>
            <div className='col-sm-6 col-md-3 text-center numHolder'>
              <div className='numholder'>
                <img className='numImg' src={mySecImage2} alt="Number" />
                <p className='ParaOne mt-2'>{courseCount}</p>
                <p className='mt-2 paraTwo'>{t('Courses')}</p>
              </div>
            </div>
            <div className='col-sm-6 col-md-3 text-center numHolder'>
              <div className='numholder'>
                <img className='numImg' src={mySecImage3} alt="Number" />
                <p className='ParaOne mt-2'>{instructorCount}</p>
                <p className='mt-2 paraTwo'>{t('Instructor')}</p>
              </div>
            </div>
            <div className='col-sm-6 col-md-3 text-center numHolder'>
              <div className='numholder'>
                <img className='numImg' src={mySecImage4} alt="Number" />
                <p className='ParaOne mt-2'>{classCount}</p>
                <p className='mt-2 paraTwo'>{t('Classes')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

{/* end number */}
{/* start instructor */}
{/* <div className='labelBackground'>
<div className="container mt-5">
  <div className="row justify-content-center">
    <div className="col-md-8"> 
      <p className="text-center Label bullet-list-A3">Our Instructors</p>
    </div>
  </div>
  <div className="row mt-5 instaHolder">
      {instructors.map((instructor, index) => (
        <div className="col-sm-6 col-md-4 mb-4" key={index}>
          <div className="text-center">
            <img className="instructorImage img-fluid" src={instructor.profileImgIRL} alt="Instructor" />
            <p className="instructorParaOne mt-3">{instructor.insName}</p>
            <p className="instructorParaTwo mt-2">{instructor.insTitle}</p>
          </div>
        </div>
      ))}
    </div>
  <div className="row">
    <div className="col-12 text-center mt-5">
      <button onClick={navtoAllInstructors} className="btnn">See More</button>
    </div>
  </div>
</div>
</div> */}

<div className='labelBackground'>
<div className="container mt-5">
  <div className="row justify-content-center">
    <div className="col-md-8"> 
      <p className="text-center Label bullet-list-A3">{t('Instructors')}</p>
    </div>
  </div>
  <div className="row mt-5 instaHolder">
  {instructors.map((item) => (
    <div className="col-sm-6 col-md-4 mb-4">
      <div className="text-center">
        <img className="instructorImage img-fluid" src={item.profileImgIRL} alt="Instructor" />
        <p className="instructorParaOne mt-3">{item.insName}</p>
        <p className="instructorParaTwo mt-2">{item.insTitle}</p>
      </div>
    </div>
 ))}
  </div>
  <div className="row">
    <div className="col-12 text-center mt-5">
      <button onClick={navtoAllInstructors} className="btnn">{t('LEARN MORE')}</button>
    </div>
  </div>
</div>
</div>

{/* end instructor */}
{/* start Testimonials */}




{/* end Testimonials */}
{/* start final part */}
<div className="container-fluid">
      <div className="row final">
        <div className="col-lg-6 col-md-12">
          <img src={mySecImagee} alt="" className="finalImage img-fluid" />
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="finalHolder">
            {/* <p className='finalOne'>
              Start your learning journey today.
            </p> */}

            <p style={{ "lineHeight":"30px","fontWeight": "500","marginTop": "6rem"}} className='finalTwo'>
              {t('Big')}
            </p>
            {/* <p className='finalTwo'>
              99.00 SR
            </p> */}
          </div>
          {/* <div className='d-flex justify-content-center mt-5'>
            <button className='btnn btn-primary'>
              SUBSCRIBE
            </button>
          </div> */}
        </div>
      </div>
    </div>


{/* end final part */}
      {/* <Footer></Footer> */}
    </div>
  )
}

export default Home
