import React from 'react'
import './CourseCatalog.css'
import Dropdown from 'react-bootstrap/Dropdown';
import banner from '../../images/GroupAbout1374.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSortDown ,faArrowRight} from '@fortawesome/free-solid-svg-icons'; // import any specific icons you need
import Header from '../header/Header';
import Footer from "../footer/Footer";
import CardComponentDynamic from '../cardComponent/CardComponentDynamic';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function CourseCatalog() {
  const whatsappNumber = "0201040277714"
  const messages = "Hello, I would like to know more about your services!";

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(messages)}`;
  const { t, i18n } = useTranslation();
  return (
    <>
   
      <Link
                to={whatsappLink}
                 target="_blank"
                 rel="noopener noreferrer"
                 style={{
                   position: 'fixed',
                   cursor:'pointer',
                   bottom: '100px',
                   right: '20px',
                   textDecoration: 'none',
                   zIndex:'100000000000',
                 }}
               >
                 <FaWhatsapp size={50} color="#25D366" />
               </Link>
    <div className='container-fluid'>
      {/* <Header></Header> */}

   <div className="row">
    
    <div  className="col-12 col-md-12 order-md-2 order-1 cardss">
        <img src={banner} alt="banner"  className="img-fluid banner" />
        <p className='subcPara1'>
      Subscribe for a great price
      </p>
        <p className='subcPara2'>
      And get access to all courses whenever you like
       </p>
        <button className='subbutton'>Subscribe now</button>
        <div className="container">
            <div className="heading">
            <p className='masterLabel'>{t('Latest')}</p>
            </div>
            {/* <div className="container cardscontainer"> */}
            <div className='row'>
<CardComponentDynamic></CardComponentDynamic>

  </div>
    {/* </div> */}
        </div>
    </div>
   </div>


    </div>
    {/* <Footer></Footer> */}
    </>
  )
}
