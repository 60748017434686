// Import Statements
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./component/home/Home";
import Header from "./component/header/Header";
import Instructors from "./component/instructors/Instructors";
import SingleInstructor from "./component/SingleInstructors/SingleInstructors.jsx";
import SingleCourse from "./component/singlecourse/SingleCourse.jsx";
import CourseCatalog from "./component/CourseCatalog/CourseCatalog.jsx";
import CourseCatalogDynamic from "./component/CourseCatalog/CourseCatalogDynamic.jsx";
import About from "./component/about/aboutUs.jsx";
import Login from "./component/Login/Login.jsx";
import Register from "./component/Register/Register.jsx";
import Contacts from "./component/contacts/contacts.jsx";
import CardComponent from './component/cardComponent/CardComponent';
import CardComponentDynamic from './component/cardComponent/CardComponentDynamic.jsx';
import TermsAndCondition from './component/terms/TermsAndCondition.jsx';
import Footer from './component/footer/Footer.jsx';
import React, { useEffect } from "react";

// AnalyticsWrapper Component for Google Analytics
const AnalyticsWrapper = ({ children }) => {
  const trackPageView = (path) => {
    if (window.gtag) {
      window.gtag('config', 'G-59TJTDBZCK', { page_path: path });
    }
  };

  useEffect(() => {
    // Track the initial page load
    trackPageView(window.location.pathname + window.location.search);

    const handleRouteChange = () => {
      trackPageView(window.location.pathname + window.location.search);
    };

    // Listen for route changes
    window.addEventListener('popstate', handleRouteChange);

    return () => {
      // Clean up the listener on component unmount
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  return <>{children}</>;
};

// App Component
function App() {
  return (
    <div className="App">
      <Router>
        <AnalyticsWrapper>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Header" element={<Header />} />
            <Route exact path="/CardComponent" element={<CardComponent />} />
            <Route exact path="/CardComponentDynamic" element={<CardComponentDynamic />} />
            <Route exact path="/Instructors" element={<Instructors />} />
            <Route exact path="/SingleInstructor/:instructorID" element={<SingleInstructor />} />
            <Route exact path="/CourseCatalog" element={<CourseCatalog />} />
            <Route exact path="/CourseCatalogDynamic" element={<CourseCatalogDynamic />} />
            <Route exact path="/About" element={<About />} />
            <Route exact path="/Login" element={<Login />} />
            <Route exact path="/Register" element={<Register />} />
            <Route exact path="/SingleCourse/:courseID" element={<SingleCourse />} />
            <Route exact path="/Contacts" element={<Contacts />} />
            <Route exact path="/TermsAndCondition" element={<TermsAndCondition />} />
          </Routes>
          <Footer />
        </AnalyticsWrapper>
      </Router>
    </div>
  );
}

export default App;
