/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState,useContext } from 'react';
import './cardComponentAll.css'
import icon from '../../images/Group-1376.png';
import icon2 from '../../images/Group-1379.png';
import course from '../../images/Group-1377.png';
import course2 from '../../images/NoPath-course.png';
import icon3 from '../../images/Group-1328.png';
import icon4 from '../../images/Group 1329.png';
import GetData from '../../services/getData';
import icon7 from '../../images/$.png';
import user from '../../images/NoPath2.png';
import icon1 from '../../images/Group-1328.png';
import star from '../../images/2.png';
import ola from '../../images/NoPath.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LanguageContext from '../../context/LanguageContext';
import { useTranslation } from 'react-i18next';

function cardComponentAll() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const { t, i18n } = useTranslation();


  useEffect(() => {
    const langId = language === "en" ? 1 : 2;
    const apiUrl = `https://na.learnovative.uk/api/v1/Front/Course/Top/${langId}`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((data) => {
      // console.log('Fetched Data:', data.data); 
       // Debugging line to check fetched data
       setData(data.data);
      // console.log('Fetched Data:', data.data[0].aboutID);

    })
    .catch((error) => console.error('Error:', error));
  }, [language]);
    const handleNavigation = (courseID) => {
        navigate(`/SingleCourse/${courseID}`);
      };
  return (
<div className='container'>
 
  {/* <h1 className='headline-instructor mt-5'>Courses you might like</h1> */}

  <div className='row'>
        {data.map((item) => ( 
<div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
<div className='course1'>
<img onClick={() => handleNavigation(item.courseID)} className='course-img'  src={item.coverImageURL}/>
<div className='row roi'>
<div className='col-5 '>
<button  className='card-button bullet-list-course2 '>{item.trackName}</button>
</div>
<div className='col-2'></div>
<div className='col-5'>
 <button className='card-button '><span ><img className='icon3-card starr ' src={star} /></span>  {item.levelTitle}</button>
 </div>
 </div>
 <div className='col-12'>
<h6 onClick={() => handleNavigation(item.courseID)} className='card-head'>{item.courseTitle}</h6>
</div>
<div className='row'>
<div className='col-6'>
<p className='lesson-instructor-numb'><span><img className='icon3-card' src={icon4}/></span> {item.lessonsCount} lesson </p>
</div>
{/* <div className='col-2'></div> */}
<div className='col-6'>
<p className='lesson-instructor-numb'><span><img className='icon3-card' src={icon3}/></span> {item.studentCount} student </p>
</div>
</div>

<hr  className='opacityy'/>
<div className='row pb-3'>
    <div className='col-6'>
    <p className='lesson-cost'>
  <span className='icon7'>{item.currencyLabel}</span>
  {item.costBefore > 0 ? (
    <>
      <span style={{ textDecoration: 'line-through', marginRight: '5px', marginLeft: '5px',fontWieght:'900',color:'red' }}>
        {item.costBefore}
      </span>
      {item.costAfter}
    </>
  ) : (
    item.costAfter
  )}
</p>
</div>
{/* <div className='col-1'></div> */}
<div className='col-6'>
<span className='instructor-lesson'><span ><img className='icon3-card' src={item.profileImgIRL} /></span>{item.insName}
</span>

</div>
</div>
{/* <div className='row'>
<div className='col-12'
 onClick={() => handleNavigation(item.courseID)}
> 
<button   className='btn btn-enroll'>Enroll Now</button>
</div>
</div> */}
</div>
</div>
 ))}
  </div>
  </div>
  )
}

export default cardComponentAll;
